import { ReactNode } from 'react';

export type TMainFactor = {
  id: number;
  title?: ReactNode;
  summary?: string;
  text?: string;
  comment?: ReactNode;
  value: ReactNode;
};

export const housingConstructionFactors: TMainFactor[] = [
  {
    id: 0,
    title: <>Введено</>,
    value: (
      <>
        <b>71,3</b>
        &nbsp; млн кв. м.
      </>
    ),
  },
  {
    id: 1,
    title: <>объем жилья в стадии строительства</>,
    value: (
      <>
        <b>117,0</b>
        &nbsp; млн кв. м.
      </>
    ),
  },
  {
    id: 2,
    title: <>расселено из аварийного жилья</>,
    value: (
      <>
        <b>75,65</b>
        &nbsp; тыс. чел
      </>
    ),
  },
];

export const roadBuildingFactors: TMainFactor[] = [
  {
    id: 0,
    text: 'дорожной сети в крупнейших городских агломерациях в нормативном состоянии',
    value: (
      <>
        <b>83,1</b>
        &nbsp; %
      </>
    ),
  },
  {
    id: 1,
    text: 'региональных дорог в нормативном состоянии',
    value: (
      <>
        <b>53,7</b>
        &nbsp; %
      </>
    ),
  },
  {
    id: 2,
    text: 'асфальтобетонного покрытия уложено в регионах',
    value: (
      <>
        <b>84,8</b>
        &nbsp; млн кв. м.
      </>
    ),
  },
];
